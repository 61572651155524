import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormType } from '../../../../models/FormTypes';
import ClientTemplateFormService from '../../../../services/ClientTemplateFormService';
import TemplateFormService from '../../../../services/TemplateFormService';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import { Item } from '../../../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import { OpenInNewTabValueRendererWrapper } from '../../../shared/form-control/DropdownSharedComponnets';
import ActionEditorBase from '../ActionEditorBase';
import { createFormTemplateSource } from '../FormTemplateSource';
import { ResourcePicklistActionData } from './ResourcePicklistAction';
import { SelectionMode, selectionModeOptions } from './SelectionModes';
import TranslatableInput from '../../../shared/form-control/TranslatableInput';
import { useCurrentClient } from '../../../../global-state/Clients';

const ResourcePicklistEditor: FC<EditorProps> = (props) => {
  const { t } = useTranslation('form-builder');
  const { action, patchData, builderBaseUrl, patchTranslations } = props;
  const { translations } = action;
  const { lockedTemplateId, selectionMode }: ResourcePicklistActionData = action?.data || {};
  const selectionModes = selectionModeOptions(t);

  const currentClient = useCurrentClient((x) => x.value);

  const templateService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateFormService(currentClient.id);
    }
    return TemplateFormService;
  }, [currentClient]);

  const {
    data: templates = [],
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['form-templates', action.id],
    queryFn: () =>
      templateService
        .getAllTemplates({
          latestOnly: true,
          includeArchived: true,
          types: [FormType.Asset, FormType.Resource],
        })
        .then((res) => {
          return res.data;
        }),
  });

  const templateOptions = useMemo(() => {
    return [{ id: '', text: t('action-properties.subform.none'), value: '' }, ...createFormTemplateSource(templates)];
  }, [t, templates]);

  const selectedTemplateForm: Item | undefined = useMemo(
    () =>
      templateOptions
        .map((x) => ('options' in x ? x.options : x))
        .flat()
        .find((x) => (x as Item).id === lockedTemplateId) as Item,
    [lockedTemplateId, templateOptions],
  );

  const onLockedTemplateChanged = (id: string) => {
    patchData({ lockedTemplateId: id || undefined });
  };

  const onSelectionModeChanged = (selectionMode: SelectionMode) => {
    patchData({ selectionMode: selectionMode });
  };

  const formLinkProvider = useCallback(
    (item: Item) => {
      return `${builderBaseUrl}/${item.id}/${item.value}`;
    },
    [builderBaseUrl],
  );

  return (
    <div data-cy="resource-picklist-editor">
      <ActionEditorBase {...props}>
        <TranslatableInput
          data-cy="button-text"
          placeholder={t('action-properties.resource-picklist.form.button-text')}
          label={t('action-properties.resource-picklist.form.button-text')}
          translationKey="buttonText"
          translations={translations ?? {}}
          onTranslationsChange={patchTranslations}
        />

        <DropdownSelect
          label={t('action-properties.resource-picklist.form.lock-template')}
          data-cy="lock-form-template"
          placeholder={t('action-properties.resource-picklist.form.lock-template')}
          options={templateOptions}
          value={
            lockedTemplateId
              ? {
                  id: lockedTemplateId,
                  text: selectedTemplateForm?.text,
                  value: selectedTemplateForm?.value as number,
                  disabled: selectedTemplateForm?.disabled,
                }
              : { id: '', text: t('action-properties.common.none-option'), value: '' }
          }
          onChange={(o) => onLockedTemplateChanged(o.id)}
          searchable
          customListRenderer={OpenInNewTabValueRendererWrapper(formLinkProvider)}
          isFetching={isFetching}
          onOpen={refetch}
        />

        <DropdownSelect
          label={t('action-properties.resource-picklist.form.number-of-selections')}
          data-cy="number-of-selections"
          placeholder={t('action-properties.resource-picklist.form.number-of-selections')}
          options={selectionModes}
          value={
            selectionMode
              ? { id: selectionMode.toString(), text: selectionModes.find((x) => x.value === selectionMode)?.text || '', value: selectionMode }
              : selectionModes[0]
          }
          onChange={(o) => onSelectionModeChanged(o.value as SelectionMode)}
        />
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />
    </div>
  );
};

export default ResourcePicklistEditor;
